.collapsed {
    width: 0;
    opacity: 0;
}

.expand-out {
    animation: expandOut 0.4s ease-in-out 0s 1;
}

.fade-up {
    animation: fadeUp 0.4s ease-in 0s 1;
}

@keyframes expandOut {
    0% {
        width: 0;
    }
}

@keyframes fadeUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
}
