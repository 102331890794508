#menuWrapper {
    position: relative;
    cursor: pointer;
}

.menu-bar {
    position: absolute;
    width: 100%;
    border-radius: 1px;
}

#menuBar1 {
    top: 0%;
}

#menuBar2 {
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 1s;
}

#menuBar3 {
    bottom: 0;
}

#menuBar1, #menuBar3 {
    transition: transform 1s, width 1s;
}

.expanded {
    #menuBar2 {
        opacity: 0;
    }

    #menuBar1 {
        transform: translateY(350%) rotate(-30deg);
        width: 110%;
    }
    #menuBar3 {
        transform: translateY(-350%) rotate(30deg);
        width: 110%;
    }
}
